import { render, staticRenderFns } from "./InsFooterLayout.vue?vue&type=template&id=3ef695fb&scoped=true&"
import script from "./InsFooterLayout.vue?vue&type=script&lang=ts&"
export * from "./InsFooterLayout.vue?vue&type=script&lang=ts&"
import style0 from "./InsFooterLayout.vue?vue&type=style&index=0&lang=less&"
import style1 from "./InsFooterLayout.vue?vue&type=style&index=1&id=3ef695fb&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ef695fb",
  null
  
)

export default component.exports