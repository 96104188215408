


























































import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    Menu: () => import('@/components/business/header/InsElMenu.vue'),
    InsCopyright: () => import('@/components/business/footer/InsCopyright.vue')
  }
})
export default class InsFooterLayout extends Vue {
  currentYear: number = 0;
  tabIndex: number = 1;
  showSub: boolean = false;

  show: boolean = true;
  content: string = '';

  toggleTab (index) {
    this.tabIndex = index;
  }

  getContent () {
    this.$Api.cms.getContentByDevice({ key: 'bottom_contact', IsMobile: true }).then(result => {
      this.content = result.CMS;
    });
  }

  created () {
    var date = new Date();
    this.currentYear = date.getFullYear();
  }

  mounted () {
    this.getContent();
  }
}
